import { useState } from "react";

import { Link } from "react-router-dom";
import { Button, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import PopUpRich from "../ui/PopUpRich";

import { createSimpleTracking } from "../../useTracking";

const useStyles = makeStyles(theme => ({
    paper: {
        position: "relative",
        display: "inline-block;",
        margin: "auto",
        width: "375px",
        "@media screen and (max-width: 450px)": {
            width: "100vw",
        },
    },
    backImage: {
        display: "block",
        maxWidth: "100%",
        height: "auto",
    },
    moveButtonArea: {
        position: "absolute",
        transform: "translate(-50%)",
        right: "0%",
        bottom: "38%",
        width: "50%",
        height: "auto",
    },
    moveButton: {
        color: "#FFFFFF",
        background: "linear-gradient(to right, #6DD1F2, #7AEBB7)",
        fontWeight: "bold",
        fontSize: "16px",
        borderRadius: "30px",
        marginTop: theme.spacing(4),
        width: "200px",
        height: "50px",
        "@media screen and (max-width: 450px)": {
            marginTop: theme.spacing(0),
            width: "48vw",
        },
    },
    contractLinkArea: {
        position: "absolute",
        transform: "translate(-50%)",
        right: "0%",
        bottom: "32%",
        width: "50%",
        height: "auto",
        textAlign: "center",
    },
    contractLink: {
        fontSize: "11px",
        color: "#0ca0eb",
        textDecoration: "underline",
        cursor: "pointer",
    },
}));

export default function Top() {
    const classes = useStyles();

    // モーダルを開くか
    // モーダルが2つあるのでそれぞれにstateを用意している
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [isModalOpen2, setIsModalOpen2] = useState(true);

    return (
        <div>
            <Paper className={classes.paper} elevation={0}>
                <img src={`${process.env.PUBLIC_URL}/icons/bg_topall.svg`} alt="" className={classes.backImage} />
                <div className={classes.moveButtonArea}>
                    <Button
                        variant="contained"
                        className={classes.moveButton}
                        component={Link}
                        color="inherit"
                        to="/question/family"
                        onClick={() => {
                            createSimpleTracking("pushedStartButton");
                        }}
                    >
                        診断START
                    </Button>
                </div>
                <div className={classes.contractLinkArea}>
                    <span
                        className={classes.contractLink}
                        onClick={() => {
                            window.open(`https://forms.gle/ZeeJuWdYJcnJcc1P7`);
                            createSimpleTracking("pushedApplicationFormLink");
                        }}
                    >
                        5,000円お祝い金申請の方はこちら
                    </span>
                </div>
                <PopUpRich
                    id={"1"}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    popUpImage={`floating_top_202501_rich.svg`}
                    buttonUrl={`https://user.tokyo-story.net/campaign_202403`}
                    buttonWord={`もっと見る`}
                    hideBackdrop={isModalOpen2}
                />
                <PopUpRich
                    id={"2"}
                    isModalOpen={isModalOpen2}
                    setIsModalOpen={setIsModalOpen2}
                    popUpImage={`floating_top_type.svg`}
                    buttonUrl={`https://user.tokyo-story.net/type`}
                    buttonWord={`あなたのタイプ別診断`}
                    hideBackdrop={false}
                />
            </Paper>
        </div>
    );
}
